import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { getPromoterStatsForOpportunityPlanning, getRecommendedUserCosts } from '../../../APIClient/planning';
import { getOpportunityResults } from '../../../APIClient/opportunities';

import { getBrandId } from '../../../Helpers/user_helpers';
import { fetchAlgoliaIndex } from '../../../Helpers/search_helpers';
import { getOpportunityRequests, getBrandRequests } from '../../../Helpers/brand_helpers';

const allTalentIndex = fetchAlgoliaIndex('sms_users_production', { faceting: ['id'] });

const OpportunityPlanner = props => {
  const {
    user,
    analytics,
    planningStrategy,
    opportunity,
    setIsFetching,
    isFetchingRef,
    page,
    curFilters,
    sortBy,
    sortByMonth,
    sortDir,
    isFetching,
    results,
    setResults,
    curSearchVal,
    hasAllResults,
    setHasAllResults,
    generosityMultiple,
    preferredSocialPlatforms
  } = props;

  const isFetchingFirstPage = isFetching && page === 0;
  const LIMIT = 40;

  // Initial Fetch, use ref for immediacy check
  const setFetchingState = state => {
    setIsFetching(state);
    isFetchingRef.current = state;
  };

  const getUserIdsToFilterFromCurFilters = () => {
    let userIdsToFilter = [];

    if (curFilters.lists?.length) {
      const listUserIds = _.flatten(curFilters.lists.map(list => list.users.map(user => user.User_id)));
      userIdsToFilter = [...userIdsToFilter, ...listUserIds];
    }

    if (curFilters.opportunities?.length) {
      const allAcceptedRequests = getOpportunityRequests(analytics).filter(request => request.userAccepted);
      const opportunityIds = curFilters.opportunities.map(op => op.id);
      const opportunityRequests = allAcceptedRequests.filter(request => opportunityIds.includes(request.Opportunity_id));
      userIdsToFilter = [...userIdsToFilter, ...opportunityRequests.map(request => request.User_id)];
    }

    if (curFilters.lookbooks) {
      const acceptedRequests = getBrandRequests(analytics).filter(request => request.userAccepted);
      const lookbookIds = curFilters.lookbooks.map(lookbook => lookbook.id);
      const lookbookRequests = acceptedRequests.filter(request => lookbookIds.includes(request.Lookbook_id));
      userIdsToFilter = [...userIdsToFilter, ...lookbookRequests.map(request => request.User_id)];
    }
    return _.uniq(userIdsToFilter);
  };

  const fetchResults = async () => {
    if (hasAllResults) return setFetchingState(false);
    let newResults = [];
    const userIdsToFilter = getUserIdsToFilterFromCurFilters();
    const expectationCount = _.sum(
      _.filter([opportunity.mentionsExpected, opportunity.linksExpected, opportunity.mentionDaysExpected, opportunity.linkingDaysExpected])
    );
    try {
      if (planningStrategy.type === 'monthly') {
        const resp = await getPromoterStatsForOpportunityPlanning({
          Brand_id: getBrandId(user),
          type: opportunity.mentionsExpected || opportunity.mentionDaysExpected ? 'mentions' : 'links',
          expectationCount,
          query: curSearchVal,
          page,
          sortBy,
          sortByMonth,
          sortDir: props.sortDir,
          generosityMultiple,
          preferredSocialPlatforms,
          limit: LIMIT,
          startMonth: moment()
            .subtract(6, 'months')
            .format('YYYY-MM-01'),
          endMonth: moment()
            .subtract(1, 'month')
            .format('YYYY-MM-01'),
          ...(userIdsToFilter.length ? { User_ids: userIdsToFilter } : {})
        });
        newResults = resp.users;
      } else if (planningStrategy.type === 'all') {
        const resp = await allTalentIndex.search(curSearchVal, {
          page: page,
          query: curSearchVal,
          hitsPerPage: LIMIT,
          ...(userIdsToFilter.length ? { facetFilters: [userIdsToFilter.map(id => `id:${id}`)] } : {})
        });
        const { userIdToCostMap } = await getRecommendedUserCosts({
          expectationCount,
          Brand_id: getBrandId(user),
          generosityMultiple,
          User_ids: resp.hits.map(result => result.id)
        });
        newResults = resp.hits.map(result => {
          return {
            ...result,
            recommended_cost: userIdToCostMap[result.id]
          };
        });
      } else if (planningStrategy.type === 'past_performance') {
        const results = await getOpportunityResults({
          Brand_id: getBrandId(user),
          page,
          sortBy: sortBy,
          sortDir: sortDir,
          ...(curFilters.opportunities?.length
            ? {
                Opportunity_ids: curFilters.opportunities.map(op => op.id),
                acceptedOnly: true
              }
            : {}),
          limit: LIMIT
        });
        newResults = results.map(result => ({ ...result, isAugmented: true }));
      }
      setResults([...results.slice(0, page * LIMIT), ...newResults]);
      setHasAllResults(newResults.length < LIMIT);
    } catch (error) {
      console.error(error);
      if (isFetchingFirstPage) {
        window.ALERT.error(`There was an issue fetching results, please try again.`);
      } else {
        window.ALERT.error(`There was an issue loading more promoters. Please try again.`);
        setHasAllResults(true);
      }
    }
    setFetchingState(false);
  };

  // Debounced Updates
  let debouncedSearch = React.useRef();
  React.useEffect(() => {
    // Wait to fetch results until user has stopped typing
    setFetchingState(true);
    clearTimeout(debouncedSearch.current);
    debouncedSearch.current = setTimeout(() => fetchResults(), curSearchVal ? 750 : 0);
  }, [curSearchVal]);

  // Immediate Updates
  const filterValues = _.values(curFilters)
    .map(i => JSON.stringify(i))
    .join('-');
  React.useEffect(() => {
    if (hasAllResults || isFetchingRef.current) return;
    setFetchingState(true);
    fetchResults();
  }, [page, sortBy, sortByMonth, sortDir, generosityMultiple, preferredSocialPlatforms, hasAllResults, filterValues]);

  return null;
};

OpportunityPlanner.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  planningStrategy: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  setIsFetching: PropTypes.func.isRequired,
  isFetchingRef: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortByMonth: PropTypes.string,
  sortDir: PropTypes.string.isRequired,
  generosityMultiple: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  results: PropTypes.array.isRequired,
  setResults: PropTypes.func.isRequired,
  hasAllResults: PropTypes.bool.isRequired,
  setHasAllResults: PropTypes.func.isRequired,
  curSearchVal: PropTypes.string.isRequired,
  curFilters: PropTypes.object.isRequired
};

export default OpportunityPlanner;
